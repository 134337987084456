import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function WysiwygEditor({ _formData, set_formData, placeholder }) {
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "link"], // --
      ["blockquote", "code-block"],

      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // --
      [{ indent: "-1" }, { indent: "+1" }], // --

      [{ size: ["small", false, "large", "huge"] }], // --

      [{ color: [] }, { background: [] }], // --
      [{ font: [] }],
      [{ align: [] }],

      ["clean"],
    ],
  };

  return (
    <div className="text-editor" style={{ backgroundColor: "white" }}>
      <ReactQuill
        theme="snow"
        placeholder={placeholder}
        modules={modules}
        value={_formData.actions ? (_formData.actions[0].data.text || "") : (_formData.description || "")}
        onChange={(e) => {
          if (_formData.actions) {
            // Handle task description (in actions[0].data.text)
            set_formData({
              ..._formData,
              actions: [
                {
                  ..._formData.actions[0],
                  data: { ..._formData.actions[0].data, text: e },
                },
              ],
            });
          } else {
            // Handle section description (directly in description field)
            set_formData({
              ..._formData,
              description: e
            });
          }
        }}
      />
    </div>
  );
}
